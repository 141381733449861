<template>
  <section class="experience">
    <div class="experience-content">
      <div class="experience-info">
        <header class="experience-header galada-regular">
          Experience is Everything
        </header>
        <span class="experience-text roboto-regular"
          >Having the freedom to control your atmosphere and what truly matters
          most to you, is what matters most to me. I focus largely on educating
          you about your options, so that you are able to make informed
          decisions about your pregnancy, labor, and birth.
        </span>
      </div>
    </div>
    <div class="experience-img">
      <div class="img-container">
        <div class="img-overlay"></div>
        <img src="@/assets/images/image7.jpg" alt="Your Experience" />
      </div>
    </div>
  </section>
</template>

<style scoped>
.experience {
  min-height: 550px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.experience-content {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: flex-end;
  justify-content: flex-end;
  text-align: right;
  padding: 60px 60px 60px 100px;
}

.experience-info {
  padding: 15px 20px;
  border: 1px solid #fefbf6;
  border-radius: 18px;
  background-color: #fefbf6;
  box-shadow: 0 0 15px #8cb9bd;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}
.experience-header {
  font-size: 28px;
  color: #b67352;
  width: 100%;
}

.experience-text {
  width: 100%;
  font-size: 20px;
}

.experience-img {
  height: 100%;
  position: relative;
  width: 50%;
  display: flex;
  padding: 20px 0 10px 15px;
  align-items: flex-end;
}

.img-overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  background-color: #fefbf6;
  border-radius: 15px;
}

.img-container {
  width: 80%;
  height: 80%;
  display: flex;
  position: relative;
}

.experience-img img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  border: 1px solid #fefbf6;
  border-radius: 18px;
  background-color: #fefbf6;
  box-shadow: 0 0 15px #ecb159;
}

@media only screen and (max-width: 500px) {
  .experience {
    flex-flow: row wrap;
    padding: 45px 0;
  }

  .experience-content {
    width: 100%;
    padding: 10px 15px;
    text-align: left;
  }

  .experience-img {
    width: 100%;
    padding: 15px;
  }

  .img-container {
    width: 100%;
    height: 100%;
  }
}
</style>
