import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/idoulathis-logo-sm.png'


const _hoisted_1 = {
  class: "main-nav navbar transparent fixed-top navbar-expand-lg",
  id: "navbar"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "collapse navbar-collapse",
  id: "mainNav"
}
const _hoisted_4 = { class: "navbar-nav ms-auto" }

import { onUnmounted, watch, nextTick, ref } from "vue";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  setup(__props) {

const route = useRoute();
const expanded = ref(false);

const handleScroll = () => {
  const navbar = document.getElementById("navbar");
  if (navbar) {
    if (window.scrollY < 75) {
      if (!navbar.classList.contains("transparent")) {
        navbar.classList.add("transparent");
      }
    } else {
      if (navbar.classList.contains("transparent")) {
        navbar.classList.remove("transparent");
      }
    }
  }
};

const manageScrollListener = (shouldAdd: boolean) => {
  if (shouldAdd) {
    window.addEventListener("scroll", handleScroll);
  } else {
    window.removeEventListener("scroll", handleScroll);
  }
};

const applyInitialClass = () => {
  const navbar = document.getElementById("navbar");
  if (navbar) {
    if (route.path === "/") {
      navbar.classList.add("transparent");
    } else {
      navbar.classList.remove("transparent");
    }
  }
};

watch(
  () => route.path,
  (newPath) => {
    applyInitialClass();

    if (newPath === "/") {
      manageScrollListener(true);
    } else {
      manageScrollListener(false);
    }

    nextTick(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    });

    const mainNav = document.getElementById("mainNav");

    if (mainNav && mainNav.classList.contains("show")) {
      mainNav.classList.remove("show");
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  manageScrollListener(false);
});

function removeTransparent() {
  const navbar = document.getElementById("navbar");

  if (navbar) {
    navbar.classList.remove("transparent");
  }
}

function checkTransparent() {
  expanded.value = !expanded.value;

  if (expanded.value && route.path === "/") {
    removeTransparent();
  } else if (!expanded.value && route.path === "/") {
    handleScroll();
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "navbar-brand",
        to: "/"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", { src: _imports_0 }, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("button", {
        onClick: checkTransparent,
        class: "navbar-toggler ms-auto",
        type: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": "#mainNav",
        "aria-controls": "mainNav",
        "aria-expanded": "false",
        "aria-label": "Toggle navigation"
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "navbar-toggler-icon" }, null, -1)
      ])),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/about",
            class: "nav-link",
            "active-class": "active",
            exact: ""
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Your Doula ")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/services",
            class: "nav-link",
            "active-class": "active",
            exact: ""
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Services ")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/gallery",
            class: "nav-link",
            "active-class": "active",
            exact: ""
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Gallery ")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}
}

})