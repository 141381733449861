import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/main-bg.png'
import _imports_1 from '@/assets/images/idoulathis-logo-sm.png'
import _imports_2 from '@/assets/images/carousel-1.png'
import _imports_3 from '@/assets/images/carousel-2.jpg'
import _imports_4 from '@/assets/images/carousel-3.png'
import _imports_5 from '@/assets/images/carousel-4.jpg'
import _imports_6 from '@/assets/images/carousel-5.jpg'


const _hoisted_1 = { class: "main" }


export default /*@__PURE__*/_defineComponent({
  __name: 'MainComponent',
  setup(__props) {

function goToContact() {
  const element = document.getElementById("contact-us");

  if (element) {
    element.scrollIntoView({
      behavior: "instant",
    });
    window.scrollBy(0, -30);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "main-bg-container" }, [
      _createElementVNode("div", { class: "overlay" }),
      _createElementVNode("img", {
        class: "main-bg",
        src: _imports_0
      })
    ], -1)),
    _createElementVNode("div", { class: "main-content" }, [
      _createElementVNode("div", { class: "main-info" }, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "main-title" }, [
          _createElementVNode("img", { src: _imports_1 })
        ], -1)),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "main-info-slogan roboto-bold" }, " Your Birth, Your Way – Compassionate Doula Care for Every Family. ", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "main-info-text roboto-regular" }, " Experience compassionate, personalized support with iDoulaThis, where your birth journey is honored and empowered. ", -1)),
        _createElementVNode("button", {
          onClick: goToContact,
          type: "button",
          class: "btn main-btn galada-regular"
        }, " Contact Me ")
      ]),
      _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"carousel-container\" data-v-723a92c0><div id=\"image-carousel\" class=\"carousel carousel-fade\" data-bs-ride=\"carousel\" data-v-723a92c0><div class=\"carousel-inner\" data-v-723a92c0><div class=\"carousel-item active\" data-v-723a92c0><img src=\"" + _imports_2 + "\" data-v-723a92c0></div><div class=\"carousel-item\" data-v-723a92c0><img src=\"" + _imports_3 + "\" data-v-723a92c0></div><div class=\"carousel-item\" data-v-723a92c0><img src=\"" + _imports_4 + "\" data-v-723a92c0></div><div class=\"carousel-item\" data-v-723a92c0><img src=\"" + _imports_5 + "\" data-v-723a92c0></div><div class=\"carousel-item\" data-v-723a92c0><img src=\"" + _imports_6 + "\" data-v-723a92c0></div></div></div></div>", 1))
    ])
  ]))
}
}

})