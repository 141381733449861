<template>
  <section class="the-village">
    <div class="the-village-message">
      <header class="the-village-header galada-regular">The Village</header>
      <span class="the-village-subheader roboto-regular">
        We believe it truly takes a village. Our mission is to connect you with
        the best local resources to empower, educate, and support you every step
        of the way.
      </span>
    </div>
    <div class="resources">
      <div class="resource">
        <div class="resource-img">
          <div class="img-overlay"></div>
          <img src="@/assets/images/joyfulmama.jpg" />
        </div>
        <div class="resource-info">
          <span class="resource-name roboto-medium">JoyfullMaMa</span>
          <span class="resource-text roboto-regular">
            Placenta Processing & Keepsakes
          </span>
          <a href="https://joyfullmama.com" target="_blank" rel="noopener">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-window"
              viewBox="0 0 16 16"
            >
              <path
                d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"
              />
              <path
                d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm13 2v2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1M2 14a1 1 0 0 1-1-1V6h14v7a1 1 0 0 1-1 1z"
              />
            </svg>
            <span>Visit JoyfullMaMa</span>
          </a>
        </div>
      </div>
      <div class="resource">
        <div class="resource-img">
          <div class="img-overlay"></div>
          <img src="@/assets/images/I1homebase.jpg" />
        </div>
        <div class="resource-info">
          <span class="resource-name roboto-medium">i1 Homebase</span>
          <span class="resource-text roboto-regular">
            Quality Sea Moss Gel, Sage, Waist Beads, Yoni Juice, and Yoni Wash
          </span>
          <a href="sms:8045857629">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-phone"
              viewBox="0 0 16 16"
            >
              <path
                d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
              />
              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
            </svg>
            <span> (804) 585-7629</span>
          </a>
          <a href="mailto:sasanchez@gmail.com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-envelope"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"
              />
            </svg>
            <span>sasanchez@gmail.com</span>
          </a>
        </div>
      </div>
      <div class="resource">
        <div class="resource-img">
          <div class="img-overlay"></div>
          <img src="@/assets/images/thenaturalapol.png" />
        </div>
        <div class="resource-info">
          <span class="resource-name roboto-medium">The Natural Apol</span>
          <span class="resource-text roboto-regular">
            Herbal remedies that promote health and restorative healing.
            Homeschool curriculums, eBooks, informative guides to plant based
            recipes, juicing, etc.
          </span>
          <a href="https://TheNaturalApol.com" target="_blank" rel="noopener">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-window"
              viewBox="0 0 16 16"
            >
              <path
                d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"
              />
              <path
                d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm13 2v2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1M2 14a1 1 0 0 1-1-1V6h14v7a1 1 0 0 1-1 1z"
              />
            </svg>
            <span>Visit The Natural Apol</span>
          </a>
        </div>
      </div>
      <div class="resource">
        <div class="resource-img">
          <div class="img-overlay"></div>
          <img src="@/assets/images/yourmidwife.png" />
        </div>
        <div class="resource-info">
          <span class="resource-name roboto-medium">Your Midwife For Life</span>
          <span class="resource-text roboto-regular">
            Dr. Michelle Shaffer (CNM) offers comprehensive women's health care,
            including Gynecology, Hypnobirthing, Menopause Care, and Fertility
            Consults. With a deep passion for reproductive health, Dr. Michelle
            has dedicated her career to Nursing and Midwifery, ensuring positive
            outcomes for her patients. Committed to helping you achieve your
            health goals, she provides both in-home and Tele-Health visits.
          </span>
          <a
            href="https://yourmidwifeforlife.com/"
            target="_blank"
            rel="noopener"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-window"
              viewBox="0 0 16 16"
            >
              <path
                d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"
              />
              <path
                d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm13 2v2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1M2 14a1 1 0 0 1-1-1V6h14v7a1 1 0 0 1-1 1z"
              />
            </svg>
            <span>Visit Your Midwife For Life</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.the-village {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 30px 0;
}

.the-village-message {
  padding: 15px 20px;
  max-width: 600px;
  margin: 35px auto 70px auto;
  border-radius: 18px;
  background-color: #fefbf6;
  box-shadow: 0 0 15px #8cb9bd;
}

.the-village-header {
  width: 100%;
  font-size: 28px;
  color: #b67352;
}

.the-village-subheader {
  width: 100%;
  font-size: 20px;
}

.resources {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 25px;
}

.resource {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  gap: 25px;
}

.resource-img {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 350px;
  width: 300px;
  position: relative;
}

.img-overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  background-color: #fefbf6;
  border-radius: 15px;
}

.resource-img img {
  height: 350px;
  width: 300px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 0 15px #ecb159;
}

.resource-info {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  border-radius: 18px;
  background-color: #fefbf6;
  box-shadow: 0 0 15px #8cb9bd;
}

.resource-name {
  width: 100%;
  text-align: left;
  font-size: 18px;
  color: #8cb9bd;
}

.resource-text {
  margin-top: 5px;
  width: 100%;
  text-align: left;
  margin-bottom: 6px;
}

.resource-info a {
  margin-top: 8px;
  color: #ecb159;
  background-color: rgb(236, 177, 89, 0.15);
  border-radius: 12px;
  padding: 10px 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  gap: 5px;
}

@media only screen and (max-width: 500px) {
  .the-village-message {
    max-width: calc(100% - 30px);
    margin: 35px auto 50px auto;
    text-align: left;
  }
}
</style>
