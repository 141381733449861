<script setup lang="ts">
function goToContact() {
  const element = document.getElementById("contact-us");

  if (element) {
    element.scrollIntoView({
      behavior: "instant",
    });
    window.scrollBy(0, -30);
  }
}
</script>
<template>
  <section class="main">
    <div class="main-bg-container">
      <div class="overlay"></div>
      <img class="main-bg" src="@/assets/images/main-bg.png" />
    </div>
    <div class="main-content">
      <div class="main-info">
        <span class="main-title">
          <img src="@/assets/images/idoulathis-logo-sm.png" />
        </span>
        <span class="main-info-slogan roboto-bold">
          Your Birth, Your Way &ndash; Compassionate Doula Care for Every
          Family.
        </span>
        <span class="main-info-text roboto-regular">
          Experience compassionate, personalized support with iDoulaThis, where
          your birth journey is honored and empowered.
        </span>
        <button
          @click="goToContact"
          type="button"
          class="btn main-btn galada-regular"
        >
          Contact Me
        </button>
      </div>
      <div class="carousel-container">
        <div
          id="image-carousel"
          class="carousel carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="@/assets/images/carousel-1.png" />
            </div>
            <div class="carousel-item">
              <img src="@/assets/images/carousel-2.jpg" />
            </div>
            <div class="carousel-item">
              <img src="@/assets/images/carousel-3.png" />
            </div>
            <div class="carousel-item">
              <img src="@/assets/images/carousel-4.jpg" />
            </div>
            <div class="carousel-item">
              <img src="@/assets/images/carousel-5.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.main-bg-container,
.main-bg-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.main-bg-container .overlay {
  background: white;
  opacity: 0.65;
}

.main-bg-container .main-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  mask-image: linear-gradient(180deg, #fcf1ff 80%, transparent);
  -webkit-mask-image: linear-gradient(180deg, #fcf1ff 80%, transparent);
}

.main-content {
  position: relative;
  z-index: 3;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0 100px;
  padding-top: 100px;
  height: 100%;
  width: 100%;
}

.main-info {
  height: 100%;
  width: 50%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
}

.main-title {
  width: 100%;
  padding-right: 60px;
}

.main-title img {
  width: 100%;
  object-fit: contain;
}

.main-info-slogan,
.main-info-text {
  width: 100%;
  text-align: left;
  color: black;
}

.main-info-slogan {
  font-size: 26px;
  margin-bottom: 10px;
}

.main-info-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.carousel-container {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
}

.carousel,
.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-item img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  border: 5px solid white;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.main-btn {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  border-width: 0;
  border-radius: 5px;
  padding: 0;
  text-decoration: underline;
  font-size: 24px;
  color: #b67352;
}

@media only screen and (max-width: 800px) {
  .main-content {
    padding: 0;
    padding-top: 60px;
    padding-bottom: 25px;
    align-content: flex-end;
    align-items: flex-end;
  }

  .main-info {
    margin-bottom: 40px;
  }

  .main-info,
  .carousel-container {
    width: 100%;
    height: unset;
    padding: 0 20px;
  }

  .carousel-container {
    height: 300px;
  }

  .main-info-slogan {
    font-size: 20px;
  }

  .main-info-text {
    font-size: 16px;
  }

  .main-btn {
    margin: 0 auto;
  }
}
</style>
