import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

import MainComponent from "@/components/MainComponent.vue";
import MainExperience from "@/components/MainExperience.vue";
import MainDoula from "@/components/MainDoula.vue";
import MainVillage from "@/components/MainVillage.vue";
import ContactComponent from "@/components/ContactComponent.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MainComponent),
    _createVNode(MainExperience),
    _createVNode(MainDoula),
    _createVNode(MainVillage),
    _createVNode(ContactComponent)
  ]))
}
}

})