<template>
  <section class="doula">
    <div class="doula-img">
      <div class="img-container">
        <div class="img-overlay"></div>
        <img src="@/assets/images/IMG001.png" alt="Your Doula - Capri Scott" />
      </div>
    </div>
    <div class="doula-content">
      <div class="doula-info">
        <header class="doula-header galada-regular">
          Meet your Doula - Capri Scott
        </header>
        <span class="doula-text roboto-regular"
          >Since 2019, I've dedicated myself to supporting families through the
          profound journey of childbirth. Fueled by a passion for ensuring every
          family receives the care and justice they deserve, I'm committed to
          making your birth experience as positive and empowering as possible.
        </span>
        <router-link to="/about" class="about-link roboto-regular">
          Discover Why I'm the Right Doula for You
        </router-link>
      </div>
    </div>
  </section>
</template>

<style scoped>
.doula {
  min-height: 550px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.doula-content {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding: 60px 100px 0 10px;
}

.doula-info {
  padding: 15px 20px;
  border: 1px solid #fefbf6;
  border-radius: 18px;
  background-color: #fefbf6;
  box-shadow: 0 0 15px #8cb9bd;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.doula-header {
  font-size: 28px;
  width: 100%;
  color: #b67352;
}

.doula-text {
  width: 100%;
  font-size: 20px;
}

.doula-img {
  height: 100%;
  position: relative;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 60px 10px 0;
}

.img-overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  background-color: #fefbf6;
  border-radius: 15px;
}

.img-container {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.doula-img img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  border: 1px solid #fefbf6;
  border-radius: 18px;
  background-color: #fefbf6;
  box-shadow: 0 0 15px #ecb159;
  object-position: center bottom;
}

.about-link {
  margin-top: 30px;
  font-size: 20px;
  color: #ecb159;
  background-color: rgb(236, 177, 89, 0.15);
  border-radius: 12px;
  padding: 10px 20px;
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  .doula {
    flex-flow: wrap-reverse;
  }
  .doula-content {
    width: 100%;
    padding: 10px 15px;
    text-align: left;
  }

  .doula-img {
    width: 100%;
    padding: 15px;
    justify-content: center;
  }
}
</style>
