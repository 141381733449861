<template>
  <section class="contact-us" id="contact-us">
    <div class="main-content">
      <div class="contact-info">
        <header class="contact-us-header galada-regular">
          Schedule A Consultation
        </header>
        <span class="contact-us-text roboto-regular">
          Ready to take the next step in your journey? Whether you're looking
          for personalized birth plans, lactation support, or virtual
          consultations, I'm here to help. Reach out today to discuss how I can
          support you through your pregnancy, birth, and beyond.
        </span>
      </div>
      <div class="contact-options">
        <a href="tel:5402871433" class="contact-option">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#8cb9bd"
            class="bi bi-telephone-fill"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
            ></path>
          </svg>
          <span class="contact-text">(540) 287-1433</span>
        </a>
        <a
          href="http://m.me/idoulathisva"
          target="_blank"
          class="contact-option"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#8cb9bd"
            class="bi bi-messenger"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z"
            ></path>
          </svg>
          <span class="contact-text">Facebook Messenger</span>
        </a>
        <a href="mailto:idoulathis@outlook.com" class="contact-option">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#8cb9bd"
            class="bi bi-envelope-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"
            ></path>
          </svg>
          <span class="contact-text">idoulathis@outlook.com</span>
        </a>
      </div>
    </div>
    <div class="gif-container">
      <img src="@/assets/images/idoulathis-work.gif" />
    </div>
  </section>
</template>

<style scoped>
.contact-us {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 50px 70px;
  position: relative;
  align-content: center;
  gap: 25px;
}

.main-content {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-flow: row wrap;
}

.contact-info {
  padding: 15px 20px;
  border: 1px solid #fefbf6;
  border-radius: 18px;
  background-color: #fefbf6;
  box-shadow: 0 0 15px #8cb9bd;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
}

.contact-us-header,
.contact-us-text {
  width: 100%;
  text-align: left;
}

.contact-us-header {
  font-size: 26px;
  color: #b67352;
}

.contact-us-text {
  width: 100%;
  font-size: 18px;
}

.contact-options {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 15px;
}

.contact-option {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: black;
}

.contact-option svg {
  width: 26px;
  height: 26px;
}

.gif-container {
  margin-top: 20px;
  max-height: 300px;
  flex: 1;
  display: flex;
  justify-content: center;
  border-radius: 8px;
}

.gif-container img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 0 15px #ecb159;
}

@media only screen and (max-width: 800px) {
  .contact-us {
    padding: 50px 20px;
    gap: unset;
  }

  .gif-container {
    flex: unset;
    width: 100%;
  }
}
</style>
